import PropTypes from 'prop-types'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Columns, Container, Hero } from 'react-bulma-components'

function HeroComponent({ page }) {
  return (
    <Hero className="is-small mb-0">
      <Hero.Body>
        <Container className="has-text-centered container content mb-0 pb-0 is-widescreen">
          <Columns className="is-centered">
            <Columns.Column className="is-8-widescreen is-12-desktop is-12-mobile">
              <h2 className="title is-size-1-desktop is-size-2-tablet is-size-2-mobile mt-2 mt-3-mobile pr-6 pl-6 is-landing-text mb-5">
                Resources to help your app succeed
              </h2>
            </Columns.Column>
            <Columns.Column className="is-7-widescreen is-11-desktop is-12-mobile mt-0 pt-0">
              <p className="is-size-4">
                Developing an app is no picnic, so here's some resources help
                you navigate the minefield and go in with eyes open.
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
HeroComponent.propTypes = {
  page: PropTypes.object,
}

export default HeroComponent
